import React from 'react'
import { graphql, Link } from 'gatsby'
import styled from 'styled-components'
import { HeroImageTitle } from 'components/new/hero'
import H2 from 'components/new/typography/h2'
import H3 from 'components/new/typography/h3'
import P from 'components/new/typography/p'
import Button from 'components/button/button'
import Layout from 'components/layout'
import Content from 'components/new/content'
import { GatsbyImage } from 'gatsby-plugin-image'
import LocationsMapSearch from 'components/locations-map/locations-map-search'
import { Seo } from '~/components/seo'
import Spacer from 'components/new/spacer'
import HeaderWithLink from 'components/new/header-with-link'
import PromotionsSlider from 'components/new/promotions-slider'
import { column } from 'styles/column'
import { clearfix } from 'styles/clearfix'

const breadcrumbs = [
  {
    name: 'Hutson Inc',
    displayName: 'Home',
    link: '/',
  },
  {
    name: 'Service',
    link: '/service/',
  },
]

const ServicePage = props => {
  const {
    data: {
      agSmasImage,
      allSanityLocation: { nodes: allSanityLocationNodes },
      allHutsonPromotion,
      heroImage,
      inspectionsImage,
      smasImage,
      powergardImage,
      sentryImage,
    },
  } = props
  const promos = allHutsonPromotion
    ? allHutsonPromotion.nodes.map(node => {
        return {
          ...node,
        }
      })
    : []
  return (
    <Layout>
      <Seo
        title={`Service`}
        description='Hutson is committed to keeping your equipment up and running. You can trust our service department for all your maintenance and repairs, in the shop or in the field.'
        breadcrumbs={breadcrumbs}
      />

      <Hero
        image={heroImage.childImageSharp.gatsbyImageData}
        title='Service'
        overlayOpacity={0.3}
      />

      <Content kind='full'>
        <H2>Why service with Hutson?</H2>
        <Spacer size='s' />
        <P>
          We're committed to keeping you up and running. We take a lot of pride in the John Deere
          name, we know how good the equipment is, but it needs to be serviced regularly and
          repaired sometimes. And when you get a service or repair done, you expect it to be done
          right the first time. We have over 120 John Deere trained technicians on staff to ensure
          that. Our parts warehouse’s are stocked so your machine isn't waiting on parts to arrive
          which means you get back out in the field or back to mowing your yard faster.
        </P>
        <P>
          For Farmers, getting equipment to our shop can be costly and time-consuming. Especially
          during planting and harvest season. We have over 50 road technicians fully equipped field
          service trucks to get to you when and where you need it most. These trucks are loaded with
          air compressors, generators, cutting torches, jacks, cranes, you name it to get the job
          done.
        </P>
        <Spacer size='m' />
        <Button as={StyledLink} to='/service/schedule-service/' color='green' ghost>
          Schedule a service
        </Button>
      </Content>
      <LightBackground>
        <Content kind='full' id='service-programs'>
          <H2>Service Programs</H2>
          <Spacer size='m' />
          <Grid>
            <Column>
              <GatsbyImage image={inspectionsImage.childImageSharp.gatsbyImageData} alt='' />
              <ColumnContent>
                <H3>Inspections</H3>
                {/* <P>...</P> */}
                <Spacer size='s' />
                <ButtonContainer>
                  <Button as={Link} to='/service/inspections/' ghost color='green'>
                    View inspection programs
                  </Button>
                </ButtonContainer>
              </ColumnContent>
            </Column>
            <Column>
              <GatsbyImage image={smasImage.childImageSharp.gatsbyImageData} alt='' />
              <ColumnContent>
                <H3>Turf Equipment Service Plans</H3>
                {/* <P>...</P> */}
                <Spacer size='s' />
                <ButtonContainer>
                  <Button as={Link} to='/service/turf-maintenance-agreements/' ghost color='green'>
                    View service plans
                  </Button>
                </ButtonContainer>
              </ColumnContent>
            </Column>
            <Column>
              <GatsbyImage image={agSmasImage.childImageSharp.gatsbyImageData} alt='' />
              <ColumnContent>
                <H3>Ag Equipment Service Plans</H3>
                {/* <P>...</P> */}
                <Spacer size='s' />
                <ButtonContainer>
                  <Button as={Link} to='/service/ag-maintenance-agreements/' ghost color='green'>
                    View service plans
                  </Button>
                </ButtonContainer>
              </ColumnContent>
            </Column>
          </Grid>
          <Spacer />
          <H2>Protection Plans & Insurance</H2>
          <Spacer size='m' />
          <Grid>
            <Column>
              <GatsbyImage image={powergardImage.childImageSharp.gatsbyImageData} alt='' />
              <ColumnContent>
                <H3>PowerGard Protection Plan</H3>
                <P>
                  Protect yourself from unexpected repairs and keep your maintenance up to date with
                  genuine parts with the PowerGard Protection Plan.
                </P>
                <Spacer size='s' />
                <ButtonContainer>
                  <Button as={Link} to='/powergard/' ghost color='green'>
                    Learn more about PowerGard
                  </Button>
                </ButtonContainer>
              </ColumnContent>
            </Column>
            <Column>
              <GatsbyImage image={sentryImage.childImageSharp.gatsbyImageData} alt='' />
              <ColumnContent>
                <H3>Sentry Insurance</H3>
                <P>
                  Farmers nationwide rely on Sentry Insurance every day. Find out why Sentry is the
                  largest writer of John Deere dealerships.
                </P>
                <Spacer size='s' />
                <ButtonContainer>
                  <Button as={Link} to='/sentry/' ghost color='green'>
                    Learn more about Sentry Insurance
                  </Button>
                </ButtonContainer>
              </ColumnContent>
            </Column>
          </Grid>
        </Content>
      </LightBackground>

      {promos.length > 0 ? (
        <Content kind='full'>
          <Spacer />
          <HeaderWithLink to='/promotions/service/' linkText='View all promotions'>
            Service Promotions
          </HeaderWithLink>
          <Spacer size='m' />
          <PromotionsSlider kind='light' items={promos} />
        </Content>
      ) : null}

      <LightBackground style={promos.length === 0 ? { backgroundColor: '#fff' } : null}>
        <Content kind='full'>
          <H2>Find a nearby service location</H2>
        </Content>
        <LocationsMapSearch locations={allSanityLocationNodes} />
      </LightBackground>
    </Layout>
  )
}

const LightBackground = styled.div`
  background-color: ${props => props.theme.color.n20};
`

const Hero = styled(HeroImageTitle)`
  height: 300px;

  @media (min-width: 900px) {
    height: 450px;
  }
`

const StyledLink = styled(Link)`
  display: inline-block;
  text-decoration: none;
`

const ButtonContainer = styled.div`
  a {
    display: inline-block;
    text-decoration: none;
  }
`

const Grid = styled.div`
  @media (min-width: 900px) {
    ${clearfix}
  }
`

const Column = styled.div`
  background-color: #fff;

  @media (max-width: 899px) {
    :not(:last-child) {
      margin-bottom: ${props => props.theme.size.l};
    }
  }

  @media (min-width: 900px) {
    ${column('1/3')}
  }
`

const ColumnContent = styled.div`
  padding: ${props => props.theme.size.m};
`

export const pageQuery = graphql`
  query servicePageQuery {
    heroImage: file(relativePath: { eq: "service/service-hero.jpg" }) {
      ...FullWidthImage
    }
    allSanityLocation(
      filter: { retailLocation: { eq: true } }
      sort: { fields: title, order: ASC }
    ) {
      nodes {
        address
        city
        directionsLink
        geolocation {
          lat
          lng
        }
        hours {
          sunday
          monday
          tuesday
          wednesday
          thursday
          friday
          saturday
        }
        locationNumber
        phoneNumber
        slug
        specialHours {
          date
          hours
          title
        }
        state
        status
        title
        zip
      }
    }
    allHutsonPromotion(
      filter: { tags: { in: ["service"] } }
      sort: { fields: [endDate], order: DESC }
    ) {
      nodes {
        ...HutsonPromo
      }
    }
    inspectionsImage: file(relativePath: { eq: "service/inspections-callout.jpg" }) {
      ...SharpImage800
    }
    smasImage: file(relativePath: { eq: "service/service-maintenance-agreements-callout.jpg" }) {
      ...SharpImage800
    }
    agSmasImage: file(
      relativePath: { eq: "service/ag-service-maintenance-agreements-callout.jpg" }
    ) {
      ...SharpImage800
    }
    sentryImage: file(relativePath: { eq: "service/sentry-family.jpg" }) {
      ...SharpImage900
    }
    powergardImage: file(relativePath: { eq: "service/powergard.jpg" }) {
      ...SharpImage900
    }
  }
`

export default ServicePage
